<template>
  <div class="dcc-card">
    <div class="dcc-header" :class="{'dcc-header-opened': visible}" @click="toggleCollapse">
      <div class="dcc-header-text">{{ title }}</div>
      <div class="dcc-header-tag" v-show="tag">{{ tag }}</div>
      <div class="dcc-header-icon">
        <i class="bx bx-chevron-right" :class="{'bx-rotate-90': visible}"></i>
      </div>

    </div>
    <b-collapse :id="id" v-model="visible">
      <div class="dcc-content">
        <slot/>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'dashboard-card-collapsable',
  props: {
    title: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    visible: false,
    id: 'collapse-' + Math.random().toString(36).substr(-5)
  }),
  methods: {
    toggleCollapse () {
      this.visible = !this.visible
    }
  }
}
</script>

<style scoped lang="scss">
.dcc-card {
  background: #FFFFFF;
  border: 1px solid #DADCE0;
  box-shadow: 0 3px 8px 4px rgba(0, 0, 0, 0.01);
  border-radius: 7px;
  position: relative;
}

.dcc-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 8px;

  &.dcc-header-opened {
    border-color: #DADCE0;
  }

  .dcc-header-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 2;
  }

  .dcc-header-tag {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    background: #45506F;
    border-radius: 42px;
    padding: 3px 12px;
    margin-left: 2em;
  }

  .dcc-header-icon {
    margin-left: auto;
    display: grid;
    place-items: center;
    font-size: 22px;
  }
}

</style>
