import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function wordCloudMetrics (roomId, { startDate, endDate } = {}) {
  const data = await http.get(`/admin/metrics/rooms/${roomId}/word-cloud-metrics`, {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    quantidade: data.wordCloud ?? 0,
    participantes: data.wordCloudAnswers ?? 0,
    taxa: (data.rateParticipantWC ?? 0) / 100
  }
}
