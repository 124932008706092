<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Nuvem criadas"
            popover="Nuvem criadas"
        >{{ totalWordClouds }}</dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Usuários participantes"
            popover="Usuários participantes"
        >{{ totalUsers }}</dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Taxa de participação"
            popover="Taxa de participação"
        >{{ participationRate }}</dashboard-big-number-a>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-for="(item, index) in wordCloudList" :key="'word-cloud-'+index">
      <b-col>
        <dashboard-word-cloud
            :title="'Nuvem ' + prefixZero(index + 1)"
            :tag="item.tag"
            :question="item.question"
            :wordList="item.wordList"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardBigNumberA from '@/components/dashboards/dashboard-big-number-a'
import DashboardWordCloud from '@/components/dashboards/dashboard-word-cloud'
import { wordCloudMetrics } from '@/modules/dashboards/http/wordCloudMetrics'
import { wordCloudResults } from '@/modules/dashboards/http/wordCloudResults'

function prefixZero (num) {
  return (num + '').padStart(2, '0')
}

export default {
  name: 'WordCloudDetail',
  components: { DashboardWordCloud, DashboardBigNumberA },
  data: () => ({
    roomId: null,
    prefixZero,
    totalWordClouds: '',
    totalUsers: '',
    participationRate: '',
    wordCloudList: []
  }),
  computed: {
    filterDates () {
      return {
        startDate: this.$store.state.dashboards.filterStartDate,
        endDate: this.$store.state.dashboards.filterEndDates
      }
    }
  },
  methods: {
    fetchData () {
      this.roomId = this.$route.params.id
      Promise.allSettled([
        this.fetchMetrics(),
        this.fetchResult()
      ])
    },
    async fetchMetrics () {
      const data = await wordCloudMetrics(this.roomId, this.filterDates)
      this.totalWordClouds = data.quantidade
      this.totalUsers = data.participantes
      this.participationRate = Math.round(data.taxa * 100) + '%'
    },
    async fetchResult () {
      const data = await wordCloudResults(this.roomId, this.filterDates)
      this.wordCloudList = data.map(item => ({
        tag: item.words.length + ' PALAVRA' + (item.words.length > 1 ? 'S' : ''),
        question: item.question,
        wordList: item.words.map(word => [word.word, word.total])
      }))
    }
  },
  watch: {
    filterDates: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      { immediate: true }
    )
  }
}
</script>

<style scoped>

</style>
