<template>
  <div class="dashboard-card">
    <div class="dashboard-card-title" v-if="!noTitle">
      {{ title }}
      <i
          class='bx bx-info-circle'
          v-b-popover.hover.top="popover"
          v-if="popover"
      />
    </div>
    <div class="dashboard-card-content h-100">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dashboard-card',
  props: {
    title: {
      type: String
    },
    popover: {
      type: String
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-card {
  position: relative;
  border-radius: 0.25rem;
  border: 0px solid #DADCE0;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding: 14px 18px;
  page-break-inside: avoid;

  .dashboard-card-title {
    font-size: 14px;
    font-weight: 500;
    color: #5F6368;
    margin-bottom: 15px;
  }

  .dashboard-card-content {
    overflow: hidden;
    width: 100%;
  }
}
</style>
