<template>
<dashboard-card-collapsable
    :title="title"
    :tag="tag"
>
  <div class="dwc-content">
    <div class="dwc-title">
      Envie uma palavra que expresse o seu sentimento agora
    </div>
    <div class="dwc-body">
      <div
          v-for="(item, index) in wordList"
          :key="'dwc-item-'+index"
          class="dwc-item"
      >
        <div class="dwc-item-title">{{ item[0] }}</div>
        <div class="dwc-item-count">{{ item[1] }}x</div>
      </div>
    </div>
  </div>
</dashboard-card-collapsable>
</template>

<script>
import DashboardCardCollapsable from '@/components/dashboards/dashboard-card-collapsable'
export default {
  name: 'dashboard-word-cloud',
  props: {
    title: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: true
    },
    question: {
      type: String,
      required: true
    },
    wordList: {
      type: Array,
      required: true
    }
  },
  components: { DashboardCardCollapsable }
}
</script>

<style scoped lang="scss">
.dwc-content {
  padding: 16px;
  .dwc-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 32px;
    color: #5F6368;
  }
  .dwc-body {
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    .dwc-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 40px;
      background-color: rgba(69, 80, 111, 0.19);
      flex: 0 0 calc(100%/10);
      max-width: calc(100%/10);
      min-width: 162px;
      padding: 4px 12px;
      color: #5F6368;
      .dwc-item-title {
        font-size: 12px;
        font-weight: 300;
      }
      .dwc-item-count {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
</style>
