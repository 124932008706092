import { render, staticRenderFns } from "./dashboard-word-cloud.vue?vue&type=template&id=0c817af4&scoped=true"
import script from "./dashboard-word-cloud.vue?vue&type=script&lang=js"
export * from "./dashboard-word-cloud.vue?vue&type=script&lang=js"
import style0 from "./dashboard-word-cloud.vue?vue&type=style&index=0&id=0c817af4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c817af4",
  null
  
)

export default component.exports