<template>
  <dashboard-card
      :title="title"
      :popover="popover"
  >
    <div class="big-number">
      <slot/>
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'

export default {
  name: 'dashboard-big-number-a',
  components: { DashboardCard },
  props: {
    title: {
      type: String,
      required: true
    },
    popover: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.big-number {
  font-size: 24px;
  font-weight: 600;
  color: #2F3844;
}
</style>
