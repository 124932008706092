import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function wordCloudResults (roomId, { startDate, endDate } = {}) {
  const data = await http.get(`/admin/metrics/rooms/${roomId}/word-cloud-results`, {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return data.data.map(item => ({
    id: item.id,
    question: item.description,
    words: item.words_group.map(({ word, total }) => ({ word, total }))
  }))
}
